$primaryColor: #00154d !default;
$primaryDarkColor: #2563eb !default;
$primaryDarkerColor: #1D4ED8 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #f4f5f7 !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import './_variables';
@import './_fonts';
@import './theme-base/components';
@import './extensions/extensions';