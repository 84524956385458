@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "./theme";

body {
    background-image: url('../assets/img/admin-bg-light.png');
    background-repeat: no-repeat;
    background-position: top;
    margin: 0;
    padding: 0;
}

.p-message {
    margin: 0;
}

p {
    margin: 0;
    padding: 16px 0;
}